@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'ClashDisplay-Regular';
        src: url('./assets/fonts/ClashDisplay-Regular.woff2');
        font-weight: 400;
        font-display: swap;
        font-style: normal;
    }

    @keyframes shake {
        0% {
            transform: translateX(0);
        }

        10%,
        90% {
            transform: translateX(-2px);
        }

        20%,
        80% {
            transform: translateX(2px);
        }

        30%,
        50%,
        70% {
            transform: translateX(-1px);
        }

        40%,
        60% {
            transform: translateX(1px);
        }

        100% {
            transform: translateX(0);
        }
    }

    .animate-shake {
        animation: shake 0.5s ease;
    }

    .scrollbar-thin {
        scrollbar-width: thin; /* Firefox */
        scrollbar-color: #d0d1d4 #edf2f7; /* Firefox */
      }
      
      .scrollbar-thin::-webkit-scrollbar {
        width: 8px; /* For vertical scrollbar */
        height: 8px; /* For horizontal scrollbar */
      }
      
      .scrollbar-thin::-webkit-scrollbar-track {
        background: #edf2f7; /* Track color */
      }
      
      .scrollbar-thin::-webkit-scrollbar-thumb {
        background-color: #4a5568; /* Thumb color */
        border-radius: 8px; /* Roundness of the thumb */
        border: 2px solid #edf2f7; /* Padding around the thumb */
      }
      
      .scrollbar-thin::-webkit-scrollbar-thumb:hover {
        background-color: #2d3748; /* Darker thumb color on hover */
      }  
}